var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-tabs', {
    attrs: {
      "vertical": "",
      "content-class": "col-12 col-md-9 mt-1 mt-md-0",
      "pills": "",
      "nav-wrapper-class": "col-md-3 col-12",
      "nav-class": "nav-left"
    }
  }, [_c('b-tab', {
    attrs: {
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "UserIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("General")])];
      },
      proxy: true
    }])
  }, [_vm.options.general ? _c('account-setting-general', {
    attrs: {
      "general-data": _vm.options.general
    }
  }) : _vm._e()], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "LockIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Change Password")])];
      },
      proxy: true
    }])
  }, [_c('account-setting-password')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }