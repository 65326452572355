var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('validation-observer', {
    ref: "form",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('b-form', {
          staticClass: "mt-2",
          attrs: {
            "novalidate": ""
          },
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.onSubmit.apply(null, arguments);
            }
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Old Password",
            "vid": "password",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "Old Password",
                  "label-for": "account-old-password"
                }
              }, [_c('b-input-group', {
                staticClass: "input-group-merge"
              }, [_c('b-form-input', {
                attrs: {
                  "id": "account-old-password",
                  "type": _vm.passwordFieldTypeOld,
                  "placeholder": "Old Password"
                },
                model: {
                  value: _vm.form.password,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "password", $$v);
                  },
                  expression: "form.password"
                }
              }), _c('b-input-group-append', {
                attrs: {
                  "is-text": ""
                }
              }, [_c('feather-icon', {
                staticClass: "cursor-pointer",
                attrs: {
                  "icon": _vm.passwordToggleIconOld
                },
                on: {
                  "click": _vm.togglePasswordOld
                }
              })], 1)], 1), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "New Password",
            "vid": "new_password",
            "rules": "required|min:6"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "account-new-password",
                  "label": "New Password"
                }
              }, [_c('b-input-group', {
                staticClass: "input-group-merge"
              }, [_c('b-form-input', {
                attrs: {
                  "id": "account-new-password",
                  "type": _vm.passwordFieldTypeNew,
                  "placeholder": "New Password"
                },
                model: {
                  value: _vm.form.new_password,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "new_password", $$v);
                  },
                  expression: "form.new_password"
                }
              }), _c('b-input-group-append', {
                attrs: {
                  "is-text": ""
                }
              }, [_c('feather-icon', {
                staticClass: "cursor-pointer",
                attrs: {
                  "icon": _vm.passwordToggleIconNew
                },
                on: {
                  "click": _vm.togglePasswordNew
                }
              })], 1)], 1), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "New Password Confirmation",
            "vid": "new_password_confirmation",
            "rules": "required|min:6|confirmed:new_password"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var errors = _ref4.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "account-retype-new-password",
                  "label": "Retype New Password"
                }
              }, [_c('b-input-group', {
                staticClass: "input-group-merge"
              }, [_c('b-form-input', {
                attrs: {
                  "id": "account-retype-new-password",
                  "type": _vm.passwordFieldTypeRetype,
                  "placeholder": "New Password"
                },
                model: {
                  value: _vm.form.new_password_confirmation,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "new_password_confirmation", $$v);
                  },
                  expression: "form.new_password_confirmation"
                }
              }), _c('b-input-group-append', {
                attrs: {
                  "is-text": ""
                }
              }, [_c('feather-icon', {
                staticClass: "cursor-pointer",
                attrs: {
                  "icon": _vm.passwordToggleIconRetype
                },
                on: {
                  "click": _vm.togglePasswordRetype
                }
              })], 1)], 1), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "mt-1 mr-1",
          attrs: {
            "variant": "primary",
            "type": "submit"
          }
        }, [_vm._v(" Save changes ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "mt-1",
          attrs: {
            "type": "reset",
            "variant": "outline-secondary"
          }
        }, [_vm._v(" Reset ")])], 1)], 1)], 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }