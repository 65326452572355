<template>
  <b-card>
    <!-- form -->
    <validation-observer
      ref="form"
      v-slot="{invalid}"
    >
      <b-form
        class="mt-2"
        novalidate
        @submit.prevent="onSubmit"
      >
        <validation-provider
          v-slot="{ errors }"
          name="Profile Image"
          vid="image"
        >
          <b-media no-body>
            <b-media-aside v-if="imagePreview">
              <b-link>
                <b-img
                  ref="previewEl"
                  style="width: 70px;height: 70px;object-fit: cover"
                  rounded
                  :src="imagePreview"
                  height="80"
                />
              </b-link>
              <!--/ avatar -->
            </b-media-aside>

            <b-media-body class="mt-75 ml-75">
              <!-- upload button -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="$refs.refInputEl.$el.click()"
              >
                Upload
              </b-button>
              <b-form-file
                ref="refInputEl"
                v-model="form.image"
                type="file"
                accept=".jpg, .png, .gif"
                :hidden="true"
                plain
                @input="onFileChange"
              />
              <!--/ upload button -->

              <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-media-body>
          </b-media>

        </validation-provider>
        <b-row class="mt-2">
          <b-col sm="6">
            <validation-provider
              v-slot="{ errors }"
              name="Name"
              vid="name"
              rules="required"
            >
              <b-form-group
                label="Name"
              >
                <b-form-input
                  v-model="form.name"
                  placeholder="Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              v-slot="{ errors }"
              name="Email"
              vid="email"
              rules="required|email"
            >
              <b-form-group
                label="Email"
              >
                <b-form-input
                  v-model="form.email"
                  placeholder="Email"
                  type="email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              v-slot="{ errors }"
              name="Phone Number"
              vid="mobile"
              rules="required"
            >
              <b-form-group
                label="Phone Number"
              >
                <b-form-input
                  v-model="form.mobile"
                  placeholder="Phone Number"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              type="submit"
            >
              Save changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetForm"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import formMixin from '@/mixins/formMixin'
import { getUserData } from '@/auth/utils'
// eslint-disable-next-line import/no-extraneous-dependencies
import { serialize } from 'object-to-formdata'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  mixins: [formMixin],
  props: {
    generalData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      profileFile: null,
      imagePreview: null,
      form: {
        mobile: null,
        name: null,
        email: null,
        image: null,
      },
      enableRedirect: false,
    }
  },
  computed: {
    toRedirectResource() {
      // eslint-disable-next-line quotes
      return `pages-profile`
    },
    userData() {
      return getUserData()
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.form.mobile = this.userData.mobile
      this.form.name = this.userData.name
      this.form.email = this.userData.email
      this.imagePreview = this.userData.image
    },
    onFileChange(e) {
      const $file = e
      this.$emit('input', $file)
      this.createInput($file)
    },
    createInput(file) {
      const reader = new FileReader()
      const vm = this
      reader.onload = e => {
        vm.imagePreview = e.target.result
      }
      reader.readAsDataURL(file)
    },
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },
    serializeForm($form) {
      const form = _.cloneDeep($form)
      if (form.image === null) {
        delete form.image
      }
      return serialize(form, {
        indices: true,
      })
    },
    afterFormSubmit(res) {
      let user = this.userData
      const getUser = _.pick(res, [
        'name',
        'email',
        'mobile',
        'image',
      ])
      this.$bvToast.toast('Updates', {
        title: 'Success',
        variant: 'success',
        solid: true,
        autoHideDelay: 5000,
        appendToast: true,
      })
      user = {
        ...user,
        ...getUser,
      }
      localStorage.setItem('userData', JSON.stringify(user))
    },
  },

}
</script>
